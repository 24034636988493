<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">French Section</h5>
      <div class="row">
        <div class="col-md-12 col-sm-12">

          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.question_type"
                rules="required"
                name="The Question Type"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="question_type"
                  size="large"
                  filterable
                  clearable
                  placeholder="Question Type"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="'Question Type'"
                  :list="builderData.questionTypes"
                  :listItemLabel="'label'"
                  :listItemValue="'value'"
                  v-model="formData.question_type">
                </fg-select>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.question"
                rules="required"
                name="The Question"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="Question"
                          name="question"
                          @keyup="generateSomeFields"
                          fou
                          v-model="formData.question">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.ident"
                rules="required"
                name="The Ident"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :disabled="editMode === true"
                          label="Question Ident"
                          name="ident"
                          fou
                          v-model="formData.ident">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>Answers List</label>
              <div class="col-12">
                <div :class="{'scrollable': isScrollable}">
                  <template class="row" v-for="(listItem,listItemIndex) in formData.answers_list">
                    <div class="row team_list_items">
                      <label>Item # {{ listItemIndex + 1 }}</label>
                      <!--                list item title-->
                      <div class="col-md-12">
                        <ValidationProvider
                          vid="fr.answers_list"
                          rules=""
                          name="The Title"
                          v-slot="{ passed, failed,errors }">
                          <fg-input type="text"
                                    :key="'title_'+listItemIndex"
                                    :error="failed ? errors[0]: null"
                                    :label="'Title '+(listItemIndex+1)"
                                    v-model="formData.answers_list[listItemIndex]">
                          </fg-input>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-sm-1 align-self-center mt-3">
                      <l-button
                        @click="removeItem(formData.answers_list, listItemIndex)"
                        type="danger" size="sm">
                        <i class="fa fa-trash"></i>
                      </l-button>
                    </div>
                  </template>
                </div>
                <l-button
                  class="mt-3"
                  type="success"
                  @click="addNewItem(formData.answers_list, '')"
                  size="sm">
                  <i class="fa fa-plus"></i>
                </l-button>
              </div>
              <button style="margin-right: 20px;" @click="generateCountries($event)" class="btn btn-default btn-fill btn-wd btn-back">Generate all countries</button>
              <button @click="clearAllAnswerData($event)" class="btn btn-danger btn-fill btn-wd btn-back">Clear all Answer data</button>
            </div>
          </div>

          <div class="form-group pt-4">
            <label>Is Active</label>&nbsp;
            <l-switch v-model="formData.is_active">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
          </div>
        </div>

      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import {Option, Select, Tooltip} from "element-ui";
  import FgSelect from "@/components/Inputs/formGroupSelect";
  import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
  import Editor from '@tinymce/tinymce-vue'
  import PrimePdfUploader from "../../../components/PrimePdfUploader";

  extend("required", required);
  extend("email", email);

  export default {
    components: {
      PrimePdfUploader,
      FormGroupTextArea,
      [Option.name]: Option,
      [Select.name]: Select,
      [Tooltip.name]: Tooltip,
      FgSelect,
      LSwitch,
      PrimeUploader,
      ImageUploader,
      PrimeVideoUploader,
      'editor': Editor
    },
    props: {
      lang: {
        type: String,
        default: 'fr'
      },
      editMode: {
        type: Boolean,
        default: false
      },
      builderData:{},
      formData:{
        type: Object,
        default() {
          return {
            question: "",
            ident: "",
            question_type: "",
            answers_list: [],
            is_active: true,
          }
        }
      },
    },
    data() {
      return {
        editorConfig: this.getEditorConfig(),
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      };
    },
    computed: {
      isScrollable() {
        return this.formData.answers_list.length > 10;
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          if (res){
            this.$emit('submit-step', this.formData, this.lang)
          }else{
            this.$emit('on-validated', res)
          }
          return res
        });
      },
      generateSomeFields() {
        if(this.editMode){
          return;
        }
        let newUrl = '';
        if (this.formData.question) {
          newUrl = this.formData.question.replace(/\s+/g, "-").toLowerCase();
          newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
          newUrl = newUrl.replace(/([-]+)/g, "_");
        }

        this.formData.ident = newUrl;
      },
      addNewItem(listItems, initObject) {
        listItems.push(initObject);
      },
      removeItem(listItems, index) {
        listItems.splice(index, 1);
      },
      generateCountries(event){
        this.loader = this.$loading.show({
          container: this.$refs.templateForm
        });
        event.preventDefault();
        this.axios.get('https://restcountries.com/v2/all')
          .then(response => {
            this.formData.answers_list = response.data.map(country => country.translations.fr);
            this.loader.hide();
          })
          .catch(error => {
            console.error(error);
            this.loader.hide();
            this.$notify({
              message: "Failed to add all countries in answers list",
              timeout: 2000,
              type: 'warning'
            });
          });
      },
      clearAllAnswerData(event){
        event.preventDefault();
        this.formData.answers_list = [];
      }
    }
  }
</script>
<style>
.scrollable {
  overflow-y: auto;
  max-height: 400px;
  overflow-x: hidden;
}
.scrollable .team_list_items{
  margin-left: 10px;
  width: 90%;
}
</style>
