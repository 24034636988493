var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v("French Section")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.question_type","rules":"required","name":"The Question Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"question_type","size":"large","filterable":"","clearable":"","placeholder":"Question Type","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Question Type',"list":_vm.builderData.questionTypes,"listItemLabel":'label',"listItemValue":'value'},model:{value:(_vm.formData.question_type),callback:function ($$v) {_vm.$set(_vm.formData, "question_type", $$v)},expression:"formData.question_type"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.question","rules":"required","name":"The Question"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Question","name":"question","fou":""},on:{"keyup":_vm.generateSomeFields},model:{value:(_vm.formData.question),callback:function ($$v) {_vm.$set(_vm.formData, "question", $$v)},expression:"formData.question"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.ident","rules":"required","name":"The Ident"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"disabled":_vm.editMode === true,"label":"Question Ident","name":"ident","fou":""},model:{value:(_vm.formData.ident),callback:function ($$v) {_vm.$set(_vm.formData, "ident", $$v)},expression:"formData.ident"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('label',[_vm._v("Answers List")]),_c('div',{staticClass:"col-12"},[_c('div',{class:{'scrollable': _vm.isScrollable}},[_vm._l((_vm.formData.answers_list),function(listItem,listItemIndex){return [_c('div',{staticClass:"row team_list_items"},[_c('label',[_vm._v("Item # "+_vm._s(listItemIndex + 1))]),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.answers_list","rules":"","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:'title_'+listItemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":'Title '+(listItemIndex+1)},model:{value:(_vm.formData.answers_list[listItemIndex]),callback:function ($$v) {_vm.$set(_vm.formData.answers_list, listItemIndex, $$v)},expression:"formData.answers_list[listItemIndex]"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-1 align-self-center mt-3"},[_c('l-button',{attrs:{"type":"danger","size":"sm"},on:{"click":function($event){return _vm.removeItem(_vm.formData.answers_list, listItemIndex)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)]})],2),_c('l-button',{staticClass:"mt-3",attrs:{"type":"success","size":"sm"},on:{"click":function($event){return _vm.addNewItem(_vm.formData.answers_list, '')}}},[_c('i',{staticClass:"fa fa-plus"})])],1),_c('button',{staticClass:"btn btn-default btn-fill btn-wd btn-back",staticStyle:{"margin-right":"20px"},on:{"click":function($event){return _vm.generateCountries($event)}}},[_vm._v("Generate all countries")]),_c('button',{staticClass:"btn btn-danger btn-fill btn-wd btn-back",on:{"click":function($event){return _vm.clearAllAnswerData($event)}}},[_vm._v("Clear all Answer data")])])]),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v("Is Active")]),_vm._v(" "),_c('l-switch',{model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }